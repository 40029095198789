<template>
  <nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
    <div class="site-navbar__header">
      <span class="site-navbar__brand" style="font-size: 20px;font-weight: bold;" @click="clickHome()">
        <a class="site-navbar__brand-lg" href="javascript:;">
          <img
        style="zoom:0.40"
        src="../../public/img/logo1.png"/>
        心狗健康管理系统</a>
        <a class="site-navbar__brand-mini" href="javascript:;">
          <!-- 心狗 -->
          <img
          style="zoom:0.40"
          src="../../public/img/logo1.png"/>
          </a>
      </span>
    </div>
    <div class="site-navbar__body clearfix">
      <el-menu
        class="site-navbar__menu"
        mode="horizontal">
        <el-menu-item class="site-navbar__switch" index="0" @click="sidebarFold = !sidebarFold">
          <i name="zhedie" class="el-icon-s-fold"></i>
        </el-menu-item>
      </el-menu>
      <component v-bind:is='currentNavBar'></component>
      <!-- <el-menu
        class="site-navbar__menu site-navbar__menu--right"
        mode="horizontal"
        >


            <el-menu-item class="site-navbar__avatar" index="3">
            <el-dropdown :show-timeout="0" placement="bottom">
                <span class="el-dropdown-link">
                <img src="~@/assets/img/touxiang.jpg" :alt="userName">{{ userName }}
                </span>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            </el-menu-item>
      </el-menu> -->

    </div>
    <!-- 弹窗, 修改密码 -->
    <!-- <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password> -->
  </nav>
</template>

<script>
  // import UpdatePassword from './main-navbar-update-password'
  // import { clearLoginInfo } from '@/utils'
  import exampleNavBar from './example_modules/navbar.vue'
  import example1NavBar from './example_module1/navbar.vue'
  import stationNavBar from './health_station/navbar.vue'
  import myh_NavBar from './personal_module/navbar.vue'
  import communityNavBar from './community_station/navbar.vue'
  import doctorNavBar from './doctor_station/navbar.vue'
  import WomenBabyNavBar from './women_baby/navbar.vue'
  import OlderStationNavBar from './older_station/navbar.vue'
  import HomeOlderNavBar from './home_older/navbar.vue'

  export default {
    data () {
      return {
        // updatePassowrdVisible: false
      }
    },
    components: {
      // UpdatePassword,
      exampleNavBar,
      example1NavBar,
      stationNavBar,
      myh_NavBar,
      communityNavBar,
      doctorNavBar,
      WomenBabyNavBar,
      OlderStationNavBar,
      HomeOlderNavBar
    },
    computed: {
      currentNavBar:function () {
        if (this.$store.state.module.moduleName === 'example_module') {
            return 'exampleNavBar'
        }
        else if (this.$store.state.module.moduleName === 'example_module1') {
          return 'example1NavBar'
        }
        else if (this.$store.state.module.moduleName === 'health_station') {
          return 'stationNavBar'
        }
        else if (this.$store.state.module.moduleName === 'personal_module') {
          return 'myh_NavBar'
        } 
        else if (this.$store.state.module.moduleName === 'community_station') {
          return 'communityNavBar'
        }
        else if (this.$store.state.module.moduleName === 'doctor_station') {
          return 'doctorNavBar'
        }
        else if (this.$store.state.module.moduleName === 'women_baby') {
          return 'WomenBabyNavBar'
        }
        else if (this.$store.state.module.moduleName === 'older_station') {
          return 'OlderStationNavBar'
        }
        else if (this.$store.state.module.moduleName === 'home_older') {
          return 'HomeOlderNavBar'
        }
        else {
          console.log("bushibabushiba")
          return 'myh_NavBar'
        }
      },

      navbarLayoutType: {
        get () { return this.$store.state.common.navbarLayoutType }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold },
        set (val) { this.$store.commit('common/updateSidebarFold', val) }
      },
      mainTabs: {
        get () { return this.$store.state.common.mainTabs },
        set (val) { this.$store.commit('common/updateMainTabs', val) }
      },
      userName: {
        get () { return this.$store.state.module.moduleName }
      }
    },
    methods: {
      clickHome(){
        this.$router.push({ name: 'home' })
      }
    }
  }
</script>
